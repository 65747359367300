.Profolio {
    background-color: #2d3436;
    text-align: center;
}
.Profolio div.section {
    height: 100vh;
}
.Profolio span.introduce {
    font-family: 'Courier New';
    font-size: 2em;
    position: relative;
    margin-top: 50vh;
    display: inline-block;
}
.Profolio span.introduce::before {
    position: absolute;
    content: '';
    top: 10%;
    right: 0;
    transform: translate(-0.5,-0.5);
    background-color: #ecf0f1;
    height: 0.85em;
    width: 2px;
    animation: infinite 0.8s input, 4s steps(15) input2;
    z-index: 1;
}
.Profolio span.introduce::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 2em;
    background-color: #2d3436;
    animation: 4s steps(15) ani;
}
.Profolio ul {
    display: inline-block;
    justify-content: center;
    font-size: 2rem;
    list-style: none;
    padding: 0px;
    display: flex;
}
.Profolio ul li { /*白底黑字 :hover => 藍底白字*/
    margin: 1em;
    padding: 0.8em;
    border-radius: 2rem;
    color: black;
    background-color:white;
}
.Profolio ul li:hover {
    color: white;
    background-color: #3498db;
    transition: 0.25s;
}
.Profolio a {
    cursor: pointer;
}
@keyframes ani {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}
@keyframes input {
    0% {
        background-color: #ecf0f1;
    }
    49% {
        background-color: #ecf0f1;
    }
    51% {
        background-color: transparent;
    }
    100% {
        background-color: transparent;
    }
}
@keyframes input2 {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}