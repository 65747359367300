.c-anime {
    background-color: #2d3436
}
.c-anime {
    margin-top: 70px;
    margin-left: 40px;
}
.c-anime .c-anime-list {
    border: 3px solid rgba(255,255,255,0.4);
    border-radius: 6px;
}