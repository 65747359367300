.image-preview-panel {
    width: 1200px;
    margin: 18px 0;
    padding: 15px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    background-color: #191919;
    border: 2px solid #ffffff;
    text-align: center;
    border-radius: 42px;
}

.image-preview-panel .img {
    vertical-align: top;
    margin: 36px;
    display: inline-block;
    width: 150px;
}

.image-preview-panel .img img {
    display: block;
    margin: auto;
    border: 2px solid #555;
    border-radius: 4px;
    padding: 5px;
    max-width: 120px;
    max-height: 500px;
    cursor: pointer;
    transition: .1s ease-in;
}
.image-preview-panel .img img:hover {
    box-shadow: 0 0 6px 3px rgba(0, 140, 186, .3);
}

.image-preview-panel .img span {
    width: 150px;
    text-align: center;
    margin-top: 20px;
    display: block;
    color: white;
}