.r18 {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #2d3436;
  text-align: center;
}
.r18 .container {
  display: block;
  margin: auto;
  padding: 6px;
  border-radius: 4px;
  width: 100%;
  max-width: 1200px;
}
.r18 .gallery {
  width: 19%;
  display: inline-block;
  position: relative;
  margin: 0 3px 5em 3px;
  vertical-align: top;
}
.r18 .gallery:hover, .r18 .gallery .caption:hover {
  cursor: pointer;
}
.r18 .gallery img {
  display: block;
  max-width: 100%;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  height: auto;
  max-height: 320px;
  margin: 0px auto;
}
.r18 i {
  margin: auto 0.2em;
}
.r18 .caption {
  line-height: 18px;
  left: 0;
  right: 0;
  top: 100%;
  overflow: hidden;
  width: 100%;
  max-height: 75px;
  padding: 3px;
  font-weight: 700;
  display: block;
  position: absolute;
  border-bottom-left-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  background-color: #404040;
  color: #d9d9d9;
  z-index: -1;
}
.r18 .caption:hover {
  cursor: default;
}
.r18 button.more {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #ecf0f1;
  border: 1px solid #2980b9;
  border-radius: 4px;
  padding: 2px 42px;
  margin-bottom: 20px;
}
.r18 button.btn {
  margin-bottom: 2em;
}
/* reader */
.r18 #reader {
  display: none; /* Hidden by default */
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: hidden;
  background-color: #1f1f1f;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}
.r18 .content {
  width: auto;
  height: auto;
  background-color: #ecf0f1;
}
.r18 .fancy-outer,
.r18 .fancy-inner {
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background-color: #1f1f1f;
}
.r18 .fancy-inner {
  overflow: auto;
}
.r18 .fancy-content {
  display: inline; /*display: block*/
  width: 100%;
  height: 100%;
}
.r18 .fancy-content img {
  max-height: 100vh;
  max-width: 100vw;
  height: auto;
  width: auto;
}
.r18 .fancy-content .page {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

/* The Close Button */
.r18 .close {
  position: absolute;
  top: 18px;
  right: 36px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
  font-size: 42px;
  font-weight: bold;
  color: #aaaaaa;
}

.r18 .close:hover,
.r18 .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.r18 #loading {
  width: 5rem;
  height: 5rem;
  border: 5px solid #f3f3f3;
  border-top: 6px solid #9c41f2;
  border-radius: 100%;
  margin: auto;
  visibility: hidden;
  animation: spin 1s infinite linear;
  z-index: 2;
  top: 50%;
  left: 50%;
  position: absolute;
}
.r18 #loading.display {
  visibility: visible;
}
.r18 .imgTopmost {
  bottom: 100px;
}
.r18 .imgTopmost-2 {
  bottom: 50px;
}

.r18 .imgTopmost, .imgTopmost-2 {
  position: fixed;
  background-color: #3498db;
  padding: 7px;
  border-radius: 5px;
  transform: translate(-0.5,-0.5);
  right: 25px;
  height: 2.4em;
  width: 2.4em;
}
.r18 .imgTopmost:hover, .imgTopmost-2:hover {
  cursor: pointer;
  background-color: white;
  transition: background-color 150ms ease-out;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.r18 .warning-yaoi {
  border: 0.2rem solid #fff;
  border-radius: 0 0 .1rem .1rem;
  /* border-bottom: none; */
  box-shadow:
    0 0 .1rem #fff,
    0 0 .1rem #fff,
    0 0 1rem #c0392b,
    0 0 0.5rem #c0392b,
    inset 0 0 0.8rem #c0392b; 
}
.r18 .warning-ntr {
  border: 0.2rem solid #fff;
  border-radius: 0 0 .1rem .1rem;
  /* border-bottom: none; */
  box-shadow:
    0 0 .1rem #fff,
    0 0 .1rem #fff,
    0 0 1rem #f1c40f,
    0 0 0.5rem #f1c40f,
    inset 0 0 0.8rem #f1c40f; 
}