.Japanese .content {
    margin: 80px 0 300px 0;
}
.Japanese .content .row {
    display: flex;
    width: 100%;
}

.Japanese .content .center{
    text-align: center;
    justify-content: center;
}