.sentence .sentence-format {
    font-size: 36px;
    text-align: center;
    font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
    padding-bottom: 20px;
}
.sentence .sentence-example {
    font-size: 24px;
    padding-left: 38%;
    font-family:  "メイリオ", Meiryo, Tahoma, Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
.sentence .sentence-example strong:nth-child(1), .sentence .sentence-format strong:nth-child(1) {
    color: #fc5c65;
}
.sentence .sentence-example strong:nth-child(2), .sentence .sentence-format strong:nth-child(2) {
    color: #4b7bec;
}
.sentence .sentence-example strong:nth-child(3), .sentence .sentence-format strong:nth-child(3) {
    color: #26de81;
}
.sentence .sentence-example strong:nth-child(4), .sentence .sentence-format strong:nth-child(4) {
    color: #45aaf2;
}
.sentence .sentence-example strong:nth-child(5), .sentence .sentence-format strong:nth-child(5) {
    color: #a55eea;
}
.sentence .sentence-example strong:nth-child(6), .sentence .sentence-format strong:nth-child(6) {
    color: #4b6584;
}
.sentence .sentence-example strong:nth-child(7), .sentence .sentence-format strong:nth-child(7) {
    color: #f7b731;
}
.sentence .sentence-remarks {
    padding-left: 38%;
    padding-top: 25px;
    white-space: pre-line;
    display: flex;
}
.sentence .sentence-remarks .icon {
    padding-right: 15px;
}
.sentence .sentence-remarks .text {
    font-size: 18px;
}