.hr-with-ball {
    box-sizing: content-box;
    background: #39D1B4;
    border: 0;
    height: 1px;
    width: 25%;
    position: relative;
    margin: 60px auto;
}
.hr-with-ball::before {
    content: "";
    width: 6px;
    height: 6px;
    background: #39D1B4;
    display: inline-block;
    border: 2px solid #39D1B4;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    left: 50%;
    margin: 0 0 0 -3px;
}