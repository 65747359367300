.navigation {
    display: flex;
}
.navigation input#show {
    display: none;
}

.navigation label { /* menus button */
    font-family: sans-serif;
    cursor: pointer;
    font-size: 28px;
    top: 25px;
    left: 30px;
    transition: .6s ease;
    color: black;
    position: fixed;
    z-index: 1;
}
.navigation .nav_inner {
    width: 250px;
    height: 100%;
    position: fixed;
    background-color: #3d3d3d;
    padding-top: 20px;
    margin-left: -250px;
    transition: .6s ease;
    overflow-y: scroll;
}

/* toggle effect */

.navigation input:checked ~ .nav_inner{
    margin-left: 0px;
}
.navigation input:checked ~ label {
    left: 280px;
}
.navigation ul li a {
    color: inherit;
    text-decoration: inherit;
}
.navigation ul {
    list-style-type: none;
}
.navigation ul li {
    color: white;
    transition: .2s linear;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.navigation ul li:hover {
    color: #0984e3;
}
.navigation span {
    color: #4b7bec;
    margin: 14px 0 4px 4px;
    display: block;
}