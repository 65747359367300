.btn {
    font-size: 20px;
    padding: 1rem 2.5rem;
    color: #2d3436;
    background-color: #74b9ff;
    border-radius: 2rem;
    border: none;
    box-shadow: 2px 2px #636e72;
}
.btn:hover {
    cursor: pointer;
}
.btn:active {
    box-shadow: 0px 0px;
}